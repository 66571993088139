<template>
  <div class="do-scroll-footer">
    <slot></slot>
  </div>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance } from "vue";
const { appContext } = getCurrentInstance();
const { $ComJs, $route } = appContext.config.globalProperties; //全局属性解构
const tabbarHeight = ref($ComJs.bili * 0.98 + "px");
onMounted(() => {
  setTimeout(() => {
    adaptiveSafeArea();
  }, 100);
});

// 适配ios 安全区域高度
function adaptiveSafeArea() {
  // 底部安全区域高度
  const safeAreaBottom = getComputedStyle(
    document.documentElement
  ).getPropertyValue("--sab");
  // tabbar 模式
  if ($route.mate && $route.mate.tabbar) {
    const tabbarEl = document.querySelector(".do-com-tabbar").clientHeight;
    if (
      tabbarEl - tabbarHeight.value > 5 ||
      tabbarEl - tabbarHeight.value < 5
    ) {
      tabbarHeight.value = tabbarEl + "px";
    }
  } else {
    tabbarHeight.value = safeAreaBottom;
  }
}

// 操控可以上拉加载
// function HandlePullUp(bol) {}
</script>
