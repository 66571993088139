<!-- 底部导航 -->
<template>
  <div
    class="do-com-drawer"
    ref="drawerWrapRef"
    :style="{
      height: props.options.height + 'px',
      transitionDuration: transitionDuration + 'ms',
      transform: ` translateX(0px) translateY(${transitionY}px) translateZ(1px) `,
      zIndex: zIndex,
    }"
    @touchstart="dragStart"
    @touchend="dragEnd"
    @touchcancel="dragEnd"
  >
    <slot name="local"></slot>
    <div
      :style="{
        overflow: 'hidden',
        height: '100%',
      }"
    >
      <div
        :style="{
          position: 'absolute',
          height: `${props.options.height}px`,
          width: '100%',
          zIndex: 1000,
          pointerEvents: 'none',
          borderRadius: '0.32rem 0.32rem 0 0',
        }"
        v-if="props.loader"
      >
        <div
          :style="{
            height: `${props.options.height - transitionY}px`,
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            display: props.options.height - transitionY <= 0 ? 'none' : 'flex',
          }"
        >
          <van-loading vertical>{{ props.loaderText }}</van-loading>
        </div>
      </div>
      <base-bscroll
        v-show="!props.loader"
        @refScroll="refScroll"
        :options="{
          bounce: {
            top: false,
            bottom: true,
            left: false,
            right: false,
          },
          specifiedIndexAsContent: 1,
          scrollY: true,
          pullUpLoad: true,
          useTransition: false,
        }"
      >
        <slot></slot>
        <template v-slot:floatHead>
          <div class="float-head">
            <slot name="head"></slot>
          </div>
        </template>
      </base-bscroll>
    </div>
  </div>
</template>

<!--  @touchmove.stop="dragMove" -->

<script setup>
import {
  ref,
  defineProps,
  onMounted,
  defineEmits,
  defineExpose,
  nextTick,
} from "vue";
/** 获取抽屉DOM */
const drawerWrapRef = ref(null);
const props = defineProps(["options", "loader", "upScroll", "loaderText"]); // 抽屉配置
const emits = defineEmits(["onChangeTransition", "pullingUp"]); // 抽屉配置
const transitionY = ref(props.options.shrink);
const topMargin = ref(0); // 距离顶部高度
const transitionDuration = ref(0); // 动画速度
/** 抽屉层级 */
let zIndex = ref(99);
/** 抽屉是否可滚动 */
let enableScroll = false;
const scrollCeontent = ref(); // base-bscroll 实例（内部滚动容器）
// 快速轻拂信息
const flickLimitData = ref({
  type: "",
  time: 0,
  distance: 0,
});
// 滚动距离值，上滑或下滑多少距离松手可以自动滚动到目标距离
// const slideHeight = props.options.shrink / 2;

// 元素挂载狗子
onMounted(() => {
  // 抽屉盒子位置信息
  const wrappRefRect = drawerWrapRef.value.getBoundingClientRect();
  topMargin.value = wrappRefRect.top - props.options.shrink;
});
function dragStart(e) {
  if (!enableScroll) {
    return false;
  }
  const startY = e.touches[0].clientY;
  const wrappRefRect = drawerWrapRef.value.getBoundingClientRect();
  if (topMargin.value === 0) {
    if (transitionY.value === props.options.shrink) {
      topMargin.value = wrappRefRect.top - props.options.shrink;
    } else if (transitionY.value === props.options.shrinkMiddle) {
      topMargin.value = wrappRefRect.top - props.options.shrinkMiddle;
    }
  }

  scrollCeontent.value.disable();
  if (
    transitionY.value <= 20 &&
    !scrollCeontent.value.enabled &&
    scrollCeontent.value.y < 0
  ) {
    console.log(" 开启");
    scrollCeontent.value.enable();
    document.ontouchmove = () => {};
    return;
  }

  // 记录快速轻拂信息
  flickLimitData.value = { ...flickLimitData.value, time: new Date() };
  document.ontouchmove = (de) => {
    // 向上滑动抽屉
    if (de.touches[0].clientY < startY) {
      let dtop = startY - de.touches[0].clientY; // 手指滑动距离值
      const moveTop = wrappRefRect.top - dtop - topMargin.value;
      console.log("asfasf", topMargin.value);
      if (moveTop <= 0) {
        document.ontouchmove = () => {};
        if (transitionY.value <= 20) {
          scrollCeontent.value.enable();
          document.ontouchmove = () => {};
          // transitionY.value = 0;
          return;
        }

        if (transitionY.value !== 0) {
          // transitionY.value = 0;
        }
        return;
      }

      // if (transitionY.value === 0 && scrollCeontent.value.enabled) {
      //   document.ontouchmove = () => {};
      //   return;
      // }

      flickLimitData.value = {
        ...flickLimitData.value,
        distance: dtop,
        moveTop: moveTop,
      };

      const space = Math.abs(
        wrappRefRect.top - Math.abs(dtop) - topMargin.value - transitionY.value
      );
      if (space >= 10 && space <= 25) {
        transitionDuration.value = 200;
        // console.log("10 - 25  4 ", moveTop + space / 4);
        transitionY.value = moveTop + space / 4;
        return;
      } else if (space >= 25 && space >= 50) {
        transitionDuration.value = 400;
        // console.log("25 - 50 400 ", moveTop + space / 3);
        transitionY.value = moveTop + space / 3;
        return;
      } else if (space >= 50) {
        transitionDuration.value = 500;
        // console.log("> 50 ", moveTop + space / 3);
        transitionY.value = moveTo + space / 3;
        return;
      }
      transitionY.value = moveTop;
    }
    // 向下滑动抽屉
    else {
      let dtop = startY - de.touches[0].clientY;
      if (scrollCeontent.value.enabled && transitionY.value <= 20) {
        document.ontouchmove = () => {};
        return;
      }
      // if (transitionY.value === 0) {
      //   if (scrollCeontent.value.y !== 0) {
      //     document.ontouchmove = () => {};
      //     if (!scrollCeontent.value.enabled) {
      //       scrollCeontent.value.enable();
      //     }
      //     return;
      //   } else if (
      //     JSON.stringify(scrollCeontent.value.y) === "0" &&
      //     scrollCeontent.value.enabled
      //   ) {
      //     scrollCeontent.value.disable();
      //   }
      // }
      // if (transitionY.value >= props.options.shrink) {
      //   document.ontouchmove = () => {};
      //   return;
      // }
      flickLimitData.value = { ...flickLimitData.value, distance: dtop };
      const space =
        wrappRefRect.top + Math.abs(dtop) - topMargin.value - transitionY.value;
      if (space >= 10 && space <= 25) {
        transitionDuration.value = 200;
        // transitionY.value = wrappRefRect.top + Math.abs(dtop) - topMargin.value;
        transitionY.value =
          wrappRefRect.top + Math.abs(dtop) - topMargin.value - space / 3;
      } else if (space >= 25 && space >= 50) {
        transitionDuration.value = 400;
        transitionY.value =
          wrappRefRect.top + Math.abs(dtop) - topMargin.value - space / 3;
      } else if (space >= 50) {
        transitionDuration.value = 700;
        transitionY.value =
          wrappRefRect.top + Math.abs(dtop) - topMargin.value - space / 3;
      } else {
        transitionY.value = wrappRefRect.top + Math.abs(dtop) - topMargin.value;
      }
    }
  };
}

function dragEnd() {
  if (!enableScroll) {
    return false;
  }
  // if (scrollCeontent.value.y !== 0) {
  //   document.ontouchmove = () => {};
  //   return;
  // }

  document.ontouchmove = () => {};
  transitionDuration.value = 800;
  const isFlick =
    new Date().getTime() - flickLimitData.value.time.getTime() < 200;
  let isbol = false;
  if (isFlick && transitionDuration.value !== 0) {
    const distance = flickLimitData.value.distance;
    const isUp = distance > 0;

    if (isUp && distance > 30) {
      console.log(
        "transitionY.value > props.options.shrinkMiddle",
        transitionY.value > props.options.shrinkMiddle
      );
      if (transitionY.value > props.options.shrinkMiddle) {
        transitionY.value = props.options.shrinkMiddle;
      } else {
        transitionY.value = 0;
      }
      isbol = true;
    } else if (!isUp && distance < -30) {
      if (transitionY.value < props.options.shrinkMiddle) {
        transitionY.value = props.options.shrinkMiddle;
      } else {
        transitionY.value = props.options.shrink;
      }
      isbol = true;
    }
  }

  if (!isbol) {
    if (
      transitionY.value <= props.options.shrinkMiddle &&
      transitionY.value >= props.options.shrinkMiddle / 3
    ) {
      transitionY.value = props.options.shrinkMiddle;
    } else if (
      transitionY.value <= props.options.shrinkMiddle &&
      transitionY.value < props.options.shrinkMiddle / 2
    ) {
      transitionY.value = 0;
    } else if (
      transitionY.value >= props.options.shrinkMiddle &&
      transitionY.value >= props.options.shrinkMiddle + 50
    ) {
      transitionY.value = props.options.shrink;
    } else if (
      transitionY.value >= props.options.shrinkMiddle &&
      transitionY.value <= props.options.shrinkMiddle + 50
    ) {
      transitionY.value = props.options.shrinkMiddle;
    } else {
      transitionY.value = props.options.shrink;
    }
    emits("onChangeTransition", transitionY.value);
    // if (
    //   (transitionY.value === props.options.shrink ||
    //     transitionY.value === props.options.shrinkMiddle) &&
    //   scrollCeontent.value.enabled
    // ) {
    //   scrollCeontent.value.disable();
    // }
    // if (
    //   (transitionY.value <= props.options.shrinkMiddle &&
    //     transitionY.value >= props.options.shrinkMiddle / 2) ||
    //   transitionY.value < props.options.shrinkMiddle + 20
    // ) {
    //   transitionY.value = props.options.shrinkMiddle;
    // } else if (transitionY.value <= props.options.shrinkMiddle / 2) {
    //   transitionY.value = 0;
    // } else {
    //   transitionY.value = props.options.shrink;
    // }
  }

  setTimeout(() => {
    transitionDuration.value = 0;
    flickLimitData.value = {
      type: "",
      time: new Date(),
      distance: 0,
    };
    // scrollCeontent.value.enable();
    // setTimeout(() => {
    //   console.log("transitionY.value", transitionY.value);
    //   if (transitionY.value <= 20) {
    //     scrollCeontent.value.enable();
    //   } else {
    //     scrollCeontent.value.disable();
    //   }
    // }, 1000);
  }, 800);
}

function refScroll(refSc) {
  scrollCeontent.value = refSc;
  refSc.on("pullingUp", () => {
    if (!props.upScroll) return;
    emits("pullingUp");
  });
  refSc.disable();
}

/** 重新计算 BetterScroll */
function scrollRefresh() {
  nextTick(() => {
    scrollCeontent.value.refresh();
  });
}

function changeScroll(y) {
  transitionDuration.value = 800;
  transitionY.value = y;
  if (y >= props.options.height) {
    zIndex = 99;
  } else {
    zIndex = 100;
  }
  setTimeout(() => {
    transitionDuration.value = 0;
  }, 800);
}

/** 控制是否可以打开 */
function toggleEnableScroll(enable) {
  enableScroll = enable;
}

/** 开启子级滚动 */
function toggleChildrenScroll(bol) {
  if (bol) {
    scrollCeontent.value.enable();
  } else {
    scrollCeontent.value.disable();
  }
}

// 自定义滚动位置
function scrollTo(x, y) {
  scrollCeontent.value.scrollTo(x, y, 300);
}

// 停止上拉加载
function finishPullUp() {
  scrollCeontent.value.finishPullUp();
}

defineExpose({
  changeScroll,
  toggleEnableScroll,
  transitionY,
  scrollRefresh,
  toggleChildrenScroll,
  scrollTo,
  finishPullUp,
});
</script>
<style lang="scss">
.float-head {
  position: absolute;
  width: 100%;
  top: 0px;
  z-index: 1000;
  transform: translateX(0px) translateY(0px) translateZ(1px);
}
.do-com-drawer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: calc(100% - 3rem);
  background: #fff;
  z-index: 100;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  transition-property: transform;
  -webkit-overflow-scrolling: touch; /* 解决ios滑动不流畅问题 */
  border-radius: 0.32rem 0.32rem 0 0;

  .do-com-scroll-wrapper {
    height: 100%;
  }
}
</style>
