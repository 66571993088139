import { createApp } from "vue";
import "@/util/storage";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import plugin from "@/mainbox/plugin.js";
import "./scss/index.scss"; //scss
import Vant from "vant";
import "@/axios/http.js";
import "vant/lib/index.css";
import vconsole from "vconsole";
import "pdfh5/css/pdfh5.css";

createApp({
  ...App,
})
  .use(store)
  .use(router)
  .use(Vant)
  .use(plugin)
  .mount("#app");

if (process.env.NODE_ENV === "development") {
  new vconsole();
}
