<!-- app 升级弹窗提示 -->
<template>
  <div>
    <!-- app 强制更新  -->
    <van-dialog
      v-model:show="forceDialog"
      title="新版本更新"
      :before-close="alertForceUpgradeConfirm"
      confirmButtonText="去更新"
      className="alert-upgrade"
      :message="`${$store.state.upgradeInfo.message}`"
    >
    </van-dialog>
    <!-- app 弱更新  -->
    <van-dialog
      v-model:show="weakDialog"
      title="提示"
      @confirm="alertUpgradeConfirm"
      @cancel="cancel"
      confirmButtonText="去更新"
      showCancelButton
      className="alert-upgrade"
      :message="`${$store.state.upgradeInfo.message}`"
    >
    </van-dialog>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { Dialog } from "vant";
import { useStore } from "vuex";
import appUpdata from "@/util/updata";
const store = useStore();

// app更新逻辑如下：
// 1.hbuild.plusready 初始化完push ，请求检查版本更新接口
//   {
//      type: "package", 资源类型  整包package 资源wgt (仅安卓)
//      upgrade_type: "force",   升级类型 强制force （弹窗无法被取消） 弱weak （弹窗可以被取消，一天内只会被提示一次）
//      message: "当前版本较低，请升级新版本", 弹窗信息
//      url: "https://imgtest.clickwifi.net/front-end-h5-cdn/test/H5BB0743F1.wgt", ios 返回打开appstore 链接， 安卓返回下载落地页
//      version: "1.0.0",
//    }

// 弱提示弹窗
const cachetUpgradeBol = ref(
  localStorage.getLocalExpire("cachetUpgradeBol") || false
);

// 强制升级提示框
const forceDialog = computed(() => {
  return store.state.upgradeInfo?.upgrade_type === "force";
});

// 弱升级提示框
const weakDialog = computed(() => {
  return (
    store.state.upgradeInfo?.upgrade_type === "weak" &&
    // 弱升级提示，是否在交互后缓存时间内，
    !cachetUpgradeBol.value &&
    // 强制更新是否显示
    !forceDialog.value
  );
});

// 提示升级
function alertUpgradeConfirm() {
  console.log("提示升级");
  // 设置提示缓存时间  86400000 一天
  localStorage.setLocalExpire("cachetUpgradeBol", true, 86400000);
  cachetUpgradeBol.value = true;
  appUpdata.downApk(store.state.upgradeInfo.url);
  store.commit("setUpgradeInfo", {});
  return false;
}
function cancel() {
  localStorage.setLocalExpire("cachetUpgradeBol", true, 86400000);
  cachetUpgradeBol.value = true;
}

// 强制升级
function alertForceUpgradeConfirm() {
  console.log("强制升级");
  appUpdata.downApk(store.state.upgradeInfo.url);
  return false;
}
</script>

<style lang="scss">
.alert-upgrade {
  .van-dialog__confirm {
    color: #0057ff;
  }
}
</style>
