/* eslint-disable no-undef */
/**
 * 本模块封装了Android、iOS的应用权限判断、打开应用权限设置界面、以及位置系统服务是否开启
 */
import gcoord from "gcoord";
import store from "@/store";
// 判断系统
function isIos() {
  if (window.plus) {
    const plus = window.plus;
    return plus.os.name == "iOS";
  }
  return false;
}
// 判断系统
function isAndroid() {
  if (window.plus) {
    const plus = window.plus;
    return plus.os.name == "Android";
  }
  return false;
}

// 判断推送权限是否开启
function judgeIosPermissionPush() {
  if (!window.plus) return;
  const plus = window.plus;
  var result = false;
  var UIApplication = plus.ios.import("UIApplication");
  var app = UIApplication.sharedApplication();
  var enabledTypes = 0;
  if (app.currentUserNotificationSettings) {
    var settings = app.currentUserNotificationSettings();
    enabledTypes = settings.plusGetAttribute("types");
    console.log("enabledTypes1:" + enabledTypes);
    if (enabledTypes == 0) {
      console.log("推送权限没有开启");
    } else {
      result = true;
      console.log("已经开启推送功能!");
    }
    plus.ios.deleteObject(settings);
  } else {
    enabledTypes = app.enabledRemoteNotificationTypes();
    if (enabledTypes == 0) {
      console.log("推送权限没有开启!");
    } else {
      result = true;
      console.log("已经开启推送功能!");
    }
    console.log("enabledTypes2:" + enabledTypes);
  }
  plus.ios.deleteObject(app);
  plus.ios.deleteObject(UIApplication);
  return result;
}

// 判断定位权限是否开启
function judgeIosPermissionLocation() {
  if (!window.plus) return;
  const plus = window.plus;
  var result = false;
  var cllocationManger = plus.ios.import("CLLocationManager");
  var status = cllocationManger.authorizationStatus();
  result = status != 2;
  console.log("定位权限开启：" + result);
  // 以下代码判断了手机设备的定位是否关闭，推荐另行使用方法 checkSystemEnableLocation
  /* var enable = cllocationManger.locationServicesEnabled();
	var status = cllocationManger.authorizationStatus();
	console.log("enable:" + enable);
	console.log("status:" + status);
	if (enable && status != 2) {
		result = true;
		console.log("手机定位服务已开启且已授予定位权限");
	} else {
		console.log("手机系统的定位没有打开或未给予定位权限");
	} */
  plus.ios.deleteObject(cllocationManger);
  return result;
}

// 判断麦克风权限是否开启
function judgeIosPermissionRecord() {
  if (!window.plus) return;
  const plus = window.plus;
  var result = false;
  var avaudiosession = plus.ios.import("AVAudioSession");
  var avaudio = avaudiosession.sharedInstance();
  var permissionStatus = avaudio.recordPermission();
  console.log("permissionStatus:" + permissionStatus);
  if (permissionStatus == 1684369017 || permissionStatus == 1970168948) {
    console.log("麦克风权限没有开启");
  } else {
    result = true;
    console.log("麦克风权限已经开启");
  }
  plus.ios.deleteObject(avaudiosession);
  return result;
}

// 判断相机权限是否开启
function judgeIosPermissionCamera() {
  if (!window.plus) return;
  const plus = window.plus;
  var result = false;
  var AVCaptureDevice = plus.ios.import("AVCaptureDevice");
  var authStatus = AVCaptureDevice.authorizationStatusForMediaType("vide");
  console.log("authStatus:" + authStatus);
  if (authStatus == 3) {
    result = true;
    console.log("相机权限已经开启");
  } else {
    console.log("相机权限没有开启");
  }
  plus.ios.deleteObject(AVCaptureDevice);
  return result;
}

// 判断相册权限是否开启
function judgeIosPermissionPhotoLibrary() {
  if (!window.plus) return;
  const plus = window.plus;
  var result = false;
  var PHPhotoLibrary = plus.ios.import("PHPhotoLibrary");
  var authStatus = PHPhotoLibrary.authorizationStatus();
  console.log("authStatus:" + authStatus);
  if (authStatus == 3) {
    result = true;
    console.log("相册权限已经开启");
  } else {
    console.log("相册权限没有开启");
  }
  plus.ios.deleteObject(PHPhotoLibrary);
  return result;
}

// 判断通讯录权限是否开启
function judgeIosPermissionContact() {
  if (!window.plus) return;
  const plus = window.plus;
  var result = false;
  var CNContactStore = plus.ios.import("CNContactStore");
  var cnAuthStatus = CNContactStore.authorizationStatusForEntityType(0);
  if (cnAuthStatus == 3) {
    result = true;
    console.log("通讯录权限已经开启");
  } else {
    console.log("通讯录权限没有开启");
  }
  plus.ios.deleteObject(CNContactStore);
  return result;
}

// 判断日历权限是否开启
function judgeIosPermissionCalendar() {
  if (!window.plus) return;
  const plus = window.plus;
  var result = false;
  var EKEventStore = plus.ios.import("EKEventStore");
  var ekAuthStatus = EKEventStore.authorizationStatusForEntityType(0);
  if (ekAuthStatus == 3) {
    result = true;
    console.log("日历权限已经开启");
  } else {
    console.log("日历权限没有开启");
  }
  plus.ios.deleteObject(EKEventStore);
  return result;
}

// 判断备忘录权限是否开启
function judgeIosPermissionMemo() {
  if (!window.plus) return;
  const plus = window.plus;
  var result = false;
  var EKEventStore = plus.ios.import("EKEventStore");
  var ekAuthStatus = EKEventStore.authorizationStatusForEntityType(1);
  if (ekAuthStatus == 3) {
    result = true;
    console.log("备忘录权限已经开启");
  } else {
    console.log("备忘录权限没有开启");
  }
  plus.ios.deleteObject(EKEventStore);
  return result;
}

// Android权限查询
function requestAndroidPermission(permissionID) {
  if (!window.plus) return;
  const plus = window.plus;
  return new Promise((resolve) => {
    plus.android.requestPermissions(
      [permissionID], // 理论上支持多个权限同时查询，但实际上本函数封装只处理了一个权限的情况。有需要的可自行扩展封装
      function (resultObj) {
        let result = 0;
        for (let i = 0; i < resultObj.granted.length; i++) {
          var grantedPermission = resultObj.granted[i];
          console.log("已获取的权限：" + grantedPermission);
          result = 1;
        }
        for (let i = 0; i < resultObj.deniedPresent.length; i++) {
          var deniedPresentPermission = resultObj.deniedPresent[i];
          console.log("拒绝本次申请的权限：" + deniedPresentPermission);
          result = 0;
        }
        for (let i = 0; i < resultObj.deniedAlways.length; i++) {
          var deniedAlwaysPermission = resultObj.deniedAlways[i];
          console.log("永久拒绝申请的权限：" + deniedAlwaysPermission);
          result = -1;
        }
        resolve(result);
        // 若所需权限被拒绝,则打开APP设置界面,可以在APP设置界面打开相应权限
        // if (result != 1) {
        // gotoAppPermissionSetting()
        // }
      },
      function (error) {
        console.log("申请权限错误：" + error.code + " = " + error.message);
        resolve({
          code: error.code,
          message: error.message,
        });
      }
    );
  });
}

// 使用一个方法，根据参数判断权限
function judgeIosPermission(permissionID) {
  if (permissionID == "location") {
    return judgeIosPermissionLocation();
  } else if (permissionID == "camera") {
    return judgeIosPermissionCamera();
  } else if (permissionID == "photoLibrary") {
    return judgeIosPermissionPhotoLibrary();
  } else if (permissionID == "record") {
    return judgeIosPermissionRecord();
  } else if (permissionID == "push") {
    return judgeIosPermissionPush();
  } else if (permissionID == "contact") {
    return judgeIosPermissionContact();
  } else if (permissionID == "calendar") {
    return judgeIosPermissionCalendar();
  } else if (permissionID == "memo") {
    return judgeIosPermissionMemo();
  }
  return false;
}

// 跳转到**应用**的权限页面
function gotoAppPermissionSetting() {
  if (!window.plus) return;
  const plus = window.plus;
  if (isIos()) {
    var UIApplication = plus.ios.import("UIApplication");
    var application2 = UIApplication.sharedApplication();
    var NSURL2 = plus.ios.import("NSURL");
    // var setting2 = NSURL2.URLWithString("prefs:root=LOCATION_SERVICES");
    var setting2 = NSURL2.URLWithString("app-settings:");
    application2.openURL(setting2);

    plus.ios.deleteObject(setting2);
    plus.ios.deleteObject(NSURL2);
    plus.ios.deleteObject(application2);
  } else {
    // console.log(plus.device.vendor);
    var Intent = plus.android.importClass("android.content.Intent");
    var Settings = plus.android.importClass("android.provider.Settings");
    var Uri = plus.android.importClass("android.net.Uri");
    var mainActivity = plus.android.runtimeMainActivity();
    var intent = new Intent();
    intent.setAction(Settings.ACTION_APPLICATION_DETAILS_SETTINGS);
    var uri = Uri.fromParts("package", mainActivity.getPackageName(), null);
    intent.setData(uri);
    mainActivity.startActivity(intent);
  }
}

// 检查系统的设备服务是否开启
// var checkSystemEnableLocation = async function () {
function checkSystemEnableLocation() {
  if (!window.plus) return;
  const plus = window.plus;
  let result = false;
  if (isIos()) {
    // var cllocationManger = plus.ios.import("CLLocationManager");
    // result = cllocationManger.locationServicesEnabled();
    // console.log("系统定位开启:" + result);
    // plus.ios.deleteObject(cllocationManger);
    // return result;
  } else {
    var context = plus.android.importClass("android.content.Context");
    var locationManager = plus.android.importClass(
      "android.location.LocationManager"
    );
    var main = plus.android.runtimeMainActivity();
    var mainSvr = main.getSystemService(context.LOCATION_SERVICE);
    result = mainSvr.isProviderEnabled(locationManager.GPS_PROVIDER);
    console.log("系统定位开启:" + result);
    return result;
  }
}

async function checkSystemAndroidLocation(callBack, isAlert = true, fail) {
  if (!window.plus) return;

  if (!isIos()) {
    var result = await requestAndroidPermission(
      "android.permission.ACCESS_FINE_LOCATION"
    );
    console.log("result", result);
    if (result == 1) {
      // 已获得授权
      console.log(" result");
      window.plus.geolocation.getCurrentPosition(
        function (p) {
          console.log(" window.plus.geolocation.getCurrentPosition");
          const [lng, lat] = gcoord.transform(
            [p.coords.longitude, p.coords.latitude], // 经纬度坐标
            gcoord.WGS84, // 当前坐标系
            gcoord.GCJ02 // 目标坐标系
          );
          store.commit("setCoordinate", `${lat},${lng}`);
          console.log("getCurrentPosition 获取系统定位", lng, lat, store.state);
          if (callBack) callBack(lat, lng);
        },
        function (e) {
          console.log(" window.plus.geolocation.getCurrentPosition");
          console.log(JSON.stringify(e));
          if (fail) {
            fail();
          }
        },
        { geocode: false }
      );
    }
    // 未获得授权 && 并且需要显示弹窗提示
    else if (isAlert) {
      plus.nativeUI.confirm(
        "e客流没有获得位置的使用权限，为了给您提供更精准的数据服务，请在设置中开启位置",
        function (e) {
          if (e.index === 1) {
            setTimeout(() => {
              var Intent = plus.android.importClass("android.content.Intent");
              var Settings = plus.android.importClass(
                "android.provider.Settings"
              );
              var Uri = plus.android.importClass("android.net.Uri");
              var mainActivity = plus.android.runtimeMainActivity();
              var intent = new Intent();
              intent.setAction(Settings.ACTION_APPLICATION_DETAILS_SETTINGS);
              var uri = Uri.fromParts(
                "package",
                mainActivity.getPackageName(),
                null
              );
              intent.setData(uri);
              mainActivity.startActivity(intent);
            }, 200);
            document.addEventListener("resume", resumeLocation, false);
          }
          if (e.index == 0) {
            if (fail) {
              fail();
            }
          }
        },
        {
          buttons: ["取消", "开启权限"],
          verticalAlign: "center",
        }
      );
    } else {
      if (fail) {
        fail();
      }
    }
  }

  // 弹窗回调重新检查
  async function resumeLocation() {
    console.log("resumeLocation 前台");
    document.removeEventListener("resume", resumeLocation, false);
    var result = await requestAndroidPermission(
      "android.permission.ACCESS_FINE_LOCATION"
    );
    if (result == 1) {
      // 已获得授权
      window.plus.geolocation.getCurrentPosition(
        function (p) {
          console.log(JSON.stringify(p));
          const [lng, lat] = gcoord.transform(
            [p.coords.longitude, p.coords.latitude], // 经纬度坐标
            gcoord.WGS84, // 当前坐标系
            gcoord.GCJ02 // 目标坐标系
          );
          store.commit("setCoordinate", `${lat},${lng}`);
          console.log("getCurrentPosition 获取系统定位", lng, lat, store.state);
          if (callBack) callBack(lat, lng);
        },
        function (e) {
          if (fail) {
            fail();
          }
          console.log(JSON.stringify(e));
        },
        { geocode: false }
      );
    } else {
      if (fail) {
        fail();
      }
    }
  }
}

function getAppLocation({
  succeed = () => {},
  isAlert = true,
  fail = () => {},
}) {
  // 默认位置修改为北京天安门
  const faillng = `22.543473`;
  const faillat = `114.055182`;

  setTimeout(() => {
    if (window.plus) {
      checkSystemAndroidLocation(succeed, isAlert, () => {
        store.commit("setCoordinate", `${faillng},${faillat}`);
        fail(faillng, faillat);
      });
      checkSystemIosLocation(succeed, isAlert, () => {
        store.commit("setCoordinate", `${faillng},${faillat}`);
        fail(faillng, faillat);
      });
    } else {
      store.commit("setCoordinate", `${faillng},${faillat}`);
      fail(faillng, faillat);
    }
  }, 200);
}

// 检查ios 是否打开定位信息
function checkSystemIosLocation(callBack, isAlert = true, fail) {
  if (!window.plus) return;
  const plus = window.plus;

  if (isIos()) {
    // 1.检查是否打开定位权限
    var cllocationManger = plus.ios.import("CLLocationManager");
    var enable = cllocationManger.locationServicesEnabled();
    var status = cllocationManger.authorizationStatus();

    // 手机系统的定位已经打开
    if (enable && status != 2) {
      console.log("手机系统的定位已经打开");
      window.plus.geolocation.getCurrentPosition(
        function (p) {
          console.log(JSON.stringify(p));
          const [lng, lat] = gcoord.transform(
            [p.coords.longitude, p.coords.latitude], // 经纬度坐标
            gcoord.WGS84, // 当前坐标系
            gcoord.GCJ02 // 目标坐标系
          );
          store.commit("setCoordinate", `${lat},${lng}`);
          console.log("getCurrentPosition 获取系统定位", lng, lat, store.state);
          if (callBack) callBack(lat, lng);
        },
        function (e) {
          if (fail) {
            fail();
          }
        },
        { geocode: false }
      );
    } else if (isAlert) {
      // 返回前台重新判断是否获取权限

      console.log("手机系统的定位没有打开");

      plus.nativeUI.confirm(
        "e客流没有获得位置的使用权限，为了给您提供更精准的数据服务，请在设置中开启位置",
        (e) => {
          if (e.index == 1) {
            setTimeout(() => {
              var UIApplication = plus.ios.import("UIApplication");
              var application2 = UIApplication.sharedApplication();
              var NSURL2 = plus.ios.import("NSURL");
              var setting2 = NSURL2.URLWithString("app-settings:");
              application2.openURL(setting2);
              plus.ios.deleteObject(setting2);
              plus.ios.deleteObject(NSURL2);
              plus.ios.deleteObject(application2);
            }, 200);
            document.addEventListener("resume", resumeLocation, false);
          }
          if (e.index == 0) {
            if (fail) {
              fail();
            }
          }
        },
        {
          buttons: ["取消", "开启权限"],
          verticalAlign: "center",
        }
      );
    }
  }

  // 弹窗回调重新检查
  function resumeLocation() {
    console.log("resumeLocation 前台");
    document.removeEventListener("resume", resumeLocation, false);
    var cllocationManger = plus.ios.import("CLLocationManager");
    var enable = cllocationManger.locationServicesEnabled();
    var status = cllocationManger.authorizationStatus();
    if (enable && status != 2) {
      window.plus.geolocation.getCurrentPosition(
        function (p) {
          console.log(JSON.stringify(p));
          const [lng, lat] = gcoord.transform(
            [p.coords.longitude, p.coords.latitude], // 经纬度坐标
            gcoord.WGS84, // 当前坐标系
            gcoord.GCJ02 // 目标坐标系
          );
          store.commit("setCoordinate", `${lat},${lng}`);
          console.log("getCurrentPosition 获取系统定位", lng, lat, store.state);
          if (callBack) callBack(lat, lng);
        },
        function (e) {
          if (fail) {
            fail();
          }
          console.log(JSON.stringify(e));
        },
        { geocode: false }
      );
    } else {
      if (fail) {
        fail();
      }
    }
  }
}

/** 打开定位开关 */
function gotoSetLocation() {
  if (!window.plus) return;
  const plus = window.plus;
  const main = plus.android.runtimeMainActivity(); //获取activity
  const Intent = plus.android.importClass("android.content.Intent");
  const Settings = plus.android.importClass("android.provider.Settings");
  const intent = new Intent(Settings.ACTION_LOCATION_SOURCE_SETTINGS); //可设置表中所有Action字段
  main.startActivity(intent);
}

function checkoutPermissionLocation() {
  if (window.plus && !isIos()) {
    var main = plus.android.runtimeMainActivity();
    var Build = plus.android.importClass("android.os.Build");
    var Manifest = plus.android.importClass("android.Manifest");
    var PackageManager = plus.android.importClass(
      "android.content.pm.PackageManager"
    );
    //上面三个导入android的类

    var Permission = Manifest.permission;
    var VERSION = Build.VERSION;
    var VERSION_CODES = Build.VERSION_CODES;
    //Permission.ACCESS_FINE_LOCATION对应定位的权限。

    var isCheck = Permission.ACCESS_FINE_LOCATION;
    var pkName = main.getPackageName();
    var packageManager = main.getPackageManager();
    var GRANTED = "";
    //android6.0以上可以动态判断是否开启权限 6.0对应的是android 23版本 VERSION_CODES.M

    if (VERSION.SDK_INT >= VERSION_CODES.M) {
      GRANTED = plus.android.invoke(main, "checkSelfPermission", isCheck);
      if (GRANTED == PackageManager.PERMISSION_DENIED) {
        console.log("没开启定位权限");
        return false;
      } else {
        console.log("启定位权限");
        return true;
      }
    } else {
      GRANTED = plus.android.invoke(
        packageManager,
        "checkPermission",
        isCheck,
        pkName
      );
      if (GRANTED == PackageManager.PERMISSION_DENIED) {
        console.log("没开启定位权限");
        return false;
      } else {
        console.log("启定位权限");
        return true;
      }
    }
  }
  return false;
}

export {
  judgeIosPermission,
  getAppLocation,
  requestAndroidPermission,
  checkSystemEnableLocation,
  gotoAppPermissionSetting,
  gotoSetLocation,
  checkSystemIosLocation,
  checkSystemAndroidLocation,
  checkoutPermissionLocation,
  isAndroid,
  isIos,
};
