if (!Storage.prototype.setLocalExpire) {
  Object.defineProperty(Storage.prototype, "setLocalExpire", {
    value(item) {
      if (!arguments[2] || isNaN(arguments[2] - 0)) {
        console.log("未设置时间");
      } else {
        let obj = {
          data: arguments[1],
          time: Date.now(),
          expire: arguments[2],
        };
        localStorage.setItem(arguments[0], JSON.stringify(obj));
      }
    },
    enumerable: false,
    writable: true,
    configurable: true,
  });
}

if (!Storage.prototype.getLocalExpire) {
  Object.defineProperty(Storage.prototype, "getLocalExpire", {
    value(key) {
      let val = localStorage.getItem(key);
      if (!val) {
        return val;
      }
      val = JSON.parse(val);
      if (Date.now() - val.time > val.expire) {
        localStorage.removeItem(key);
        return null;
      }
      return val.data;
    },
    enumerable: false,
    writable: true,
    configurable: true,
  });
}

if (!Storage.prototype.setSessionExpire) {
  Object.defineProperty(Storage.prototype, "setSessionExpire", {
    value(item) {
      if (!arguments[2] || isNaN(arguments[2] - 0)) {
        console.log("未设置时间");
      } else {
        let obj = {
          data: arguments[1],
          time: Date.now(),
          expire: arguments[2],
        };
        sessionStorage.setItem(arguments[0], JSON.stringify(obj));
      }
    },
    enumerable: false,
    writable: true,
    configurable: true,
  });
}

if (!Storage.prototype.getSessionExpire) {
  Object.defineProperty(Storage.prototype, "getSessionExpire", {
    value(key) {
      let val = sessionStorage.getItem(key);
      if (!val) {
        return val;
      }
      val = JSON.parse(val);
      if (Date.now() - val.time > val.expire) {
        sessionStorage.removeItem(key);
        return null;
      }
      return val.data;
    },
    enumerable: false,
    writable: true,
    configurable: true,
  });
}

if (!Storage.prototype.clearLocal) {
  Object.defineProperty(Storage.prototype, "clearLocal", {
    value(item) {
      console.log(item);
      if (Object.prototype.toString.call(item) === "[object Array]") {
        let key = {};
        item.forEach((v) => {
          key[v] = localStorage.getItem(v);
        });
        localStorage.clear();
        Object.keys(key).forEach((v) => {
          localStorage.setItem(v, key[v]);
        });
      } else {
        console.error(
          "localStorage.clearLocal: parameter format error,  expect Array[String]. --es.shim/storage.js:95"
        );
      }
    },
    enumerable: false,
    writable: true,
    configurable: true,
  });
}

if (!Storage.prototype.clearSession) {
  Object.defineProperty(Storage.prototype, "clearSession", {
    value(item) {
      console.log(item);
      if (Object.prototype.toString.call(item) === "[object Array]") {
        let key = {};
        item.forEach((v) => {
          key[v] = sessionStorage.getItem(v);
        });
        sessionStorage.clear();
        Object.keys(key).forEach((v) => {
          sessionStorage.setItem(v, key[v]);
        });
      } else {
        console.error(
          "sessionStorage.clearSession: parameter format error,  expect Array[String]. --es.shim/storage.js:118"
        );
      }
    },
    enumerable: false,
    writable: true,
    configurable: true,
  });
}
