import {
  checkSystemAndroidLocation,
  checkSystemIosLocation,
} from "@/js_sdk/permission.js";
/**H+plus公共 */
import router from "../router";
import store from "../store";
import { getCurrentInstance } from "vue";
const { appContext } = getCurrentInstance() || {};
const { $ComJs } = appContext?.config?.globalProperties || {}; //全局属性解构
import AppUpdata from "./updata";
class Hbuild {
  constructor() {
    this.imm = null;
    this.InputMethodManager = null;
    this.nativeWebview = null;
    this.appVersion = "";
  }

  //初始化plus
  plusready() {
    document.addEventListener(
      "plusready",
      () => {
        this.getVersion();
        this.pause();
        this.resume();
        this.getBack();
        // this.getGeocode();
        this.closeStartImg();
        this.setStateBar();
        AppUpdata.checkUpdate();
        store.commit("clearCommonData");
      },
      false
    );
  }

  // 获取版本
  getVersion() {
    // 手机环境
    const self = this;
    if (navigator.userAgent.indexOf("Html5Plus") > -1 && window.plus) {
      const plus = window.plus;
      plus.runtime.getProperty(plus.runtime.appid, (app) => {
        let appVersion = app.version; // 获取app版本 upAppBol
        self.appVersion = appVersion;
        store.commit("setAppVersion", appVersion);
      });
    }
    // web
    else {
      const version = require("../../package.json").version;
      this.appVersion = version;
      store.commit("setAppVersion", version);
    }
  }
  getSystemType() {
    let u = navigator.userAgent;
    if (window.plus) {
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; // android终端
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isAndroid) return 2;
      if (isIOS) return 1;
    }
    return 3;
  }
  //原生返回
  getBack() {
    if (window.plus) {
      var backButtonPress = 0;
      const plus = window.plus;
      plus.key.addEventListener(
        "backbutton",
        function () {
          console.log("backbutton 调用");
          const curRoute = router.currentRoute.value,
            RouteName = curRoute.name;
          // console.log("curRoute", curRoute., curRoute.name);
          // 强制升级提示框
          const forceDialog = store.state.upgradeInfo.upgrade_type === "force";
          console.log("backbutton", curRoute);
          if (
            RouteName === "datamap" ||
            RouteName === "login" ||
            RouteName === "my" ||
            forceDialog
          ) {
            backButtonPress++;
            if (backButtonPress > 1) {
              plus.runtime.quit();
            } else {
              plus.nativeUI.toast("再按一次退出应用");
            }
            setTimeout(function () {
              backButtonPress = 0;
            }, 1000);
          } else if (curRoute.meta.power == 1) {
            router.push("/");
          } else {
            var webview = plus.webview.currentWebview();
            webview.canBack(function (e) {
              if (e.canBack) {
                webview.back();
              } else {
                backButtonPress++;
                if (backButtonPress > 1) {
                  plus.runtime.quit();
                } else {
                  plus.nativeUI.toast("再按一次退出应用");
                }
                setTimeout(function () {
                  backButtonPress = 0;
                }, 1000);
              }
            });
          }
        },
        false
      );
    }
  }
  /**
   * 获得状态栏高度
   */
  getStatusBarHeight() {
    if (window.plus) {
      return window.plus.navigator.getStatusbarHeight();
    }
    return 0;
  }
  dicernPhone() {
    let u = navigator.userAgent;
    let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; // android终端
    let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    if (isAndroid) {
      return 0;
    } else if (isIOS) {
      return 1;
    }
  }
  // 通过定位模块获取位置信息
  async getGeocode() {
    if (!window.plus) return;
    if (!+localStorage.getItem("agreement")) {
      return;
    }
    if (this.dicernPhone() === 1) {
      checkSystemIosLocation();
    } else {
      checkSystemAndroidLocation();
    }
  }
  //获取设备ID
  getUuid() {
    let uuid = new Date().getTime();
    if (window.plus) {
      uuid = window.plus.device.uuid || new Date().getTime();
    }
    return uuid;
  }
  //关闭启动图
  closeStartImg() {
    if (window.plus) window.plus.navigator.closeSplashscreen();
  }
  pause() {
    document.addEventListener(
      "pause",
      function () {
        // console.log("切换到后台")
      },
      false
    );
  }
  resume() {
    document.addEventListener(
      "resume",
      () => {
        // console.log("切换到前台")
        // this.getGeocode();
      },
      false
    );
  }
  //设置状态栏
  setStateBar(color = "dark") {
    if (window.plus) {
      window.plus.navigator.setStatusBarStyle("dark");
    }
  }
  getStateBar() {
    if (window.plus) {
      return window.plus.navigator.getStatusBarStyle();
    }
  }
  //获取缓存大小
  getCacheSize() {
    return new Promise((resolve) => {
      if (window.plus) {
        window.plus.cache.calculate(function (size) {
          resolve(Math.floor((size / 1024 / 1024) * 100) / 100 + "M");
        });
      } else {
        resolve("");
      }
    });
  }
  //清除缓存
  clearCache() {
    if (window.plus) {
      window.plus.cache.clear();
      window.plus.runtime.restart();
    }
  }
  //改变屏幕方向
  lockOrientation(info) {
    if (window.plus) window.plus.screen.lockOrientation(info);
  }
  // 显示软键盘
  showSoftInput(className) {
    // window.plus 判断是app还是  h5
    if (window.plus && $ComJs) {
      if ($ComJs.dicernPhone() === 0) {
        this.imm.toggleSoftInput(0, this.InputMethodManager.SHOW_FORCED);
      } else {
        this.nativeWebview.plusCallMethod({
          setKeyboardDisplayRequiresUserAction: false,
        });
      }
    }

    setTimeout(function () {
      //此处可写具体逻辑设置获取焦点的input
      var inputElem = document.querySelector(className);
      inputElem.focus();
    }, 100);
  }
  // 获取app软键盘属性
  initNativeObjects() {
    // window.plus 判断是app还是  h5
    if (!window.plus || !$ComJs) return;
    if ($ComJs.dicernPhone() === 0) {
      var main = window.plus.android.runtimeMainActivity();
      var Context = window.plus.android.importClass("android.content.Context");
      this.InputMethodManager = window.plus.android.importClass(
        "android.view.inputmethod.InputMethodManager"
      );
      this.imm = main.getSystemService(Context.INPUT_METHOD_SERVICE);
    } else {
      this.nativeWebview = window.plus.webview
        .currentWebview()
        .nativeInstanceObject();
    }
  }

  // 同意隐私政策
  adoptPrivacy() {
    // window.plus 判断是app还是  h5
    if (!window.plus) return;
    console.log("adoptPrivacy--1");
    window.plus.runtime.agreePrivacy();
    // this.getGeocode();
  }
}

const _Hbuild = new Hbuild();
export default _Hbuild;
