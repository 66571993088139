import { checkUpdateVersion } from "@/axios/common";
import { Toast } from "vant";
import store from "../store";
// 判断系统
function isIos() {
  if (window.plus) {
    const plus = window.plus;
    return plus.os.name == "iOS";
  }
  return false;
}

// App更新
class AppUpdata {
  constructor() {
    this.version = false;
  }
  // 检查更新
  async checkUpdate() {
    if (!window.plus) return;
    const plus = window.plus;
    const self = this;
    plus.runtime.getProperty(plus.runtime.appid, async (app) => {
      let appVersion = app.version; // 获取app版本 upAppBol
      const res = await checkUpdateVersion();
      console.log("检查更新当前版本号", appVersion);
      console.log("服务端检查版本数据", res, res.data.type);
      if (res && res.data.url) {
        // 安卓自动热更新
        if (res.data.type === "wgt" && !isIos()) {
          // 资源更新
          self.downWgt(res.data.url);
        } else if (res.data.type !== "wgt") {
          // 全局更新数据信息
          console.log("res.data ", res.data);
          store.commit("setUpgradeInfo", res.data || {});
        }
      }
    });
  }

  downWgt(wgtUrl) {
    if (!window.plus) return;
    const plus = window.plus;
    const self = this;
    plus.downloader
      .createDownload(
        wgtUrl,
        { filename: "_doc/update/" },
        function (d, status) {
          if (status == 200) {
            console.log("下载wgt成功：" + d.filename);
            self.installWgt(d.filename); // 安装wgt包
          } else {
            console.log("下载wgt失败！");
          }
        }
      )
      .start();
  }

  // 安卓打开落地页 / ios 打开appstroe
  downApk(url) {
    if (!window.plus) return;
    const plus = window.plus;
    if (isIos()) {
      plus.runtime.openURL(url);
    } else {
      plus.runtime.openURL(url);
    }
  }

  // 更新应用资源
  installWgt(path) {
    if (!window.plus) return;
    const plus = window.plus;
    plus.runtime.install(
      path,
      {},
      function () {
        Toast("app资源更新成功");
        plus.runtime.restart();
        // plus.nativeUI.alert("应用资源更新完成！", function () {
        // });
      },
      function (e) {
        console.log("安装wgt文件失败[" + e.code + "]：" + e.message);
        plus.nativeUI.alert("安装wgt文件失败[" + e.code + "]：" + e.message);
      }
    );
  }
}

const _AppUpdata = new AppUpdata();
export default _AppUpdata;
