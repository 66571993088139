<!--betterScroll组件封装 -->
<template>
  <div
    ref="wrapper"
    :class="`do-com-scroll-wrapper ${
      props.safeareaNavbar ? 'do-com-scroll-navbar' : ''
    }`"
    :style="style"
  >
    <slot name="floatHead"></slot>
    <div ref="contentWrapper" class="do-com-scroll-content">
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
import BScroll from "better-scroll";
import {
  ref,
  onMounted,
  defineProps,
  defineEmits,
  getCurrentInstance,
} from "vue";
const { appContext } = getCurrentInstance();
const { $ComJs, $Hbuild } = appContext.config.globalProperties; //全局属性解构
// props
const props = defineProps(["options", "safeareaNavbar"]);
const emits = defineEmits(["pullingDown", "refScroll"]);

// ref
const wrapper = ref();
const contentWrapper = ref();
const scrollWrapper = ref();

const isAndroid = $ComJs.dicernPhone() === 0;
const style =
  props.safeareaNavbar && isAndroid
    ? {
        top: $Hbuild.getStatusBarHeight() + $ComJs.bili * 0.88 + "px",
      }
    : {};

onMounted(() => {
  setTimeout(() => {
    initBScroll();
    console.log("style111", style);
  }, 200);
});

const options = {
  pullDownRefresh: false, //下拉刷新
  pullUpLoad: false, //上拉加载
  eventPassthrough: "horizontal",
  click: false,
  probeType: 0, //获取滚动位置的方式
  scrollX: false,
  scrollY: true,
  snap: false, //slide组件
  bounce: true, //回弹
  momentum: true, //当快速滑动时是否开启滑动惯性
  stopPropagation: false, //是否阻止冒泡
  ...(props.options || {}),
};
function initBScroll() {
  if (!wrapper.value) {
    return;
  }

  computeSlideHeight();
  computeSlideWidth();
  // if (self.options.scrollX) self.options.eventPassthrough = "vertical";
  if (options.scrollX) options.eventPassthrough = "vertical";
  const scroll = new BScroll(wrapper.value, options);
  scrollWrapper.value = scroll;
  if (options.pullDownRefresh) {
    scrollWrapper.value.on("pullingDown", () => {
      // pulldownLoadAnimation.value.stop();
      emits("pullingDown", scrollWrapper.value, finishPullDown);
    });
  }
  emits("refScroll", scroll);
}

// 结束下拉刷新
function finishPullDown() {
  scrollWrapper.value.finishPullDown();
  if (options.pullDownRefresh) {
  }
  setTimeout(() => {
    scrollWrapper.value.refresh();
  }, 200);
}

//计算高
function computeSlideHeight() {
  let height = 0;
  if (options.scrollY) {
    if (options.firstChildrenHeight) {
      const children = contentWrapper.value.children;
      height = children[0].offsetHeight;
    } else if (options.resetHeight) {
      //需要计算每一个子节点的高
      const children = contentWrapper.value.children;
      for (let i = 0; i < children.length; i++) {
        let child = children[i],
          oneChild = children[0];
        let _offsetHeight = Math.floor(oneChild.offsetHeight);
        _offsetHeight =
          _offsetHeight % 2 == 0 ? _offsetHeight : _offsetHeight + 1;
        height += _offsetHeight;
        child.style.height = _offsetHeight + "px";
        child.style.lineHeight = _offsetHeight + "px";
        wrapper.value.style.height = _offsetHeight + "px";
      }
    } else {
      height = wrapper.value.offsetHeight + 1;
    }
    contentWrapper.value.style.minHeight = height + "px";
  }
}

// 计算宽
function computeSlideWidth() {
  if (options.scrollX) {
    const children = contentWrapper.value.children;
    let width = 0;
    let slideWidth = wrapper.value.clientWidth;
    if (options.countWay) {
      //以第一个子元素为宽度
      for (let i = 0; i < children.length; i++) {
        let child = children[0];
        child.style.width = Math.floor(child.offsetWidth) + "px";
        width += Math.floor(child.offsetWidth);
      }
    } else if (options.slide) {
      //以屏幕的宽度
      const len = children.length;
      for (let i = 0; i < len; i++) {
        let child = children[i];
        child.style.width = slideWidth + "px";
        width += slideWidth;
      }
      if (options.snap && options.snap.loop) {
        width += 2 * slideWidth;
      }
    } else {
      width += wrapper.value.offsetWidth + 1;
    }
    contentWrapper.value.style.width = width + "px";
  }
}

// 操控可以上拉加载
// function HandlePullUp(bol) {}
</script>
<style lang="scss" scoped>
.do-com-scroll-content {
  position: relative;
  z-index: 10;
}
.do-com-pulldown-wrapper {
  position: absolute;
  pointer-events: none;
  top: 0;
  z-index: 0;
  width: 100%;
  font-size: 0.24rem;
  color: rgba(255, 255, 255, 0.5);
  height: 1rem;
  overflow: hidden;
  @include flex();
  img {
    width: 1rem;
  }
}
</style>
