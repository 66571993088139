import { getAccountInfo } from "@/axios/user";
const defaultState = {
  token: "",
  phone: "",
  info: {
    region: [],
  },
};

export default {
  state: { ...defaultState },
  mutations: {
    setToken(state, val) {
      state.token = val;
    },
    setPhone(state, val) {
      state.phone = val;
    },
    setUserInfo(state, val) {
      state.info = { ...val };
    },
    setUserClear(state) {
      state.token = "";
      state.phone = "";
      state.info = {
        region: [],
      };
    },
  },
  actions: {
    // 获取用户信息
    async getAccountInfo({ commit }) {
      try {
        const res = await getAccountInfo();
        commit("setUserInfo", res.data);
      } catch (error) {}
    },

    async logout({ commit }) {
      commit("setUserClear");
      commit("clearCommon");
      let agreement = localStorage.getItem("agreement");
      localStorage.clear();
      // 用户退出不能清除用户的服务协议
      localStorage.setItem("agreement", agreement);
    },
  },
};
