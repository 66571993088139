<template>
  <div class="do-com-dialogTreaty">
    <van-popup
      v-model:show="show"
      round
      position="bottom"
      :style="{
        height: 'auto',
        padding: '0.24rem 0.24rem 0.4rem 0.24rem',
      }"
      :close-on-click-overlay="false"
    >
      <div class="title">服务协议及隐私政策</div>
      <div class="top4">欢迎使用E客流</div>
      <div class="top4">你的一切个人信息公用于为您提供服务</div>
      <div class="top4">我们将会全力保护您的信息，请你仔细阅读完整版</div>
      <div class="dialog">
        <span @click="() => goWebPage(1)">《<span>用户服务协议</span>》</span>
        与
        <span @click="() => goWebPage(2)">《<span>隐私政策</span>》</span>
      </div>
      <div class="btn">
        <van-button
          round
          plain
          color="rgba(2, 4, 15, 0.45)"
          @click="getDisagreePrivacy"
          >不同意</van-button
        >
        <van-button round color="#0057FF" @click="adopt">同意</van-button>
      </div>
    </van-popup>
  </div>
</template>

<script setup>
import { ref, getCurrentInstance, onMounted, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { Toast } from "vant";

const { appContext } = getCurrentInstance();
const { $Hbuild } = appContext.config.globalProperties; //全局属性解构
let show = ref(false);
let router = useRouter();
let route = useRoute();

onMounted(() => {
  console.log("window.plus", window.plus);
  if (window.plus) {
    const plus = window.plus;

    if (plus.os.name !== "Android") {
      show.value = !+localStorage.getItem("agreement");
    } else {
      show.value = false;
    }
  }
});

watch(
  () => route.path,
  (end, sta) => {
    // 判断协议页面不显示这个组件

    if (end === "/web-slot") {
      show.value = false;
    } else {
      // 判断系统

      if (window.plus) {
        const plus = window.plus;
        console.log("plus.os.name", plus.os.name);
        if (plus.os.name !== "Android") {
          show.value = !+localStorage.getItem("agreement");
        } else {
          show.value = false;
        }
      }

      console.log("isAndroid 111");
    }
  }
);
// 跳转协议页面
const goWebPage = (type) => {
  // show.value = false;
  const url =
    type === 1
      ? process.env.VUE_APP_USER_AGREEMENT_URL
      : process.env.VUE_APP_PRIVACY_AGREEMENT_URL;
  if (window.plus) {
    window.plus.runtime.openURL(url);
  } else {
    window.open(url);
  }
  // router.push({
  //   path: "/web-slot",
  //   query: {
  //     type,
  //   },
  // });
};

// 不同意
const getDisagreePrivacy = () => {
  localStorage.setItem("agreement", 0);
  Toast({
    message: "若不同意本隐私政策，很遗憾我们将无法给你提供服务",
  });
};
// 同意
const adopt = () => {
  show.value = false;
  localStorage.setItem("agreement", 1);
  $Hbuild.adoptPrivacy();
};
</script>
<style lang="scss" scoped>
.do-com-dialogTreaty {
  .top4 {
    margin-bottom: 0.04rem;
  }
  .title {
    font-size: 0.42rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: bold;
    color: #03050f;
    padding: 0.24rem 0 0.48rem;
  }
  & > div {
    height: 0.34rem;
    font-size: 0.24rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(2, 4, 15, 0.85);
  }
  .dialog {
    padding: 0.24rem 0 0.48rem;
    span {
      font-weight: bold;
      color: #0057ff;
      span {
        font-size: 0.28rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: bold;
        color: #0057ff;
        text-decoration: underline;
      }
    }
  }
  .btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0.24rem;
    button {
      width: 30%;
    }
    button + button {
      width: 60%;
    }
  }
}
</style>
