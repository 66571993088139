<template>
  <div
    id="app"
    @touchmove.prevent
    :class="{
      app: $ComJs.curFacility.isApp,
      'ios-x': $ComJs.curFacility.isX,
    }"
  >
    <!-- app 更新弹窗  -->
    <upgrade-dialog></upgrade-dialog>
    <dialog-treaty></dialog-treaty>
    <router-view v-slot="{ Component }" v-if="!$store.state.refresh">
      <keep-alive ref="keepref">
        <component
          :is="Component"
          :key="$route.name"
          v-if="$route.meta.keepAlive"
        />
      </keep-alive>
      <transition :name="$store.state.animate">
        <component
          :is="Component"
          :key="$route.name"
          v-if="!$route.meta.keepAlive"
        />
      </transition>
    </router-view>
    <base-footer v-if="$route.meta.tabbar"></base-footer>
  </div>
</template>

<script setup>
import { getCurrentInstance, onMounted, ref } from "vue";
import upgradeDialog from "@/components/upgradeDialog";
import DialogTreaty from "@/components/dialogTreaty";
const { appContext } = getCurrentInstance();
const { $Hbuild } = appContext.config.globalProperties; //全局属性解构

// 启动 plusready
onMounted(() => {
  $Hbuild.plusready();
  // 公共接口-获取城市
});
</script>

<style lang="scss">
// 3D视距
#app {
  overflow: hidden;
  // background: red;
}
#search {
  position: fixed;
  top: 0;
}
</style>
