/**
 * Vue 插件
 */
import ComJs from "@/util/index.js";
import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";
import Hbuild from "@/util/hbuild.js";
import FastClick from "fastclick";

export default {
  // 接收两个参数
  // app: 应用上下文的实例
  // options：插件输入的选项
  install: (app) => {
    app.config.globalProperties.$ComJs = ComJs;
    app.config.globalProperties.$Hbuild = Hbuild;
    // 可以消除移动浏览器上物理点击和触发点击事件之间300ms的延迟
    FastClick.attach(document.body);
    FastClick.prototype.focus = function (targetElement) {
      var length;
      var isIOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      // Issue #160: on iOS 7, some input elements (e.g. date datetime month) throw a vague TypeError on setSelectionRange. These elements don't have an integer value for the selectionStart and selectionEnd properties, but unfortunately that can't be used for detection because accessing the properties also throws a TypeError. Just check the type instead. Filed as Apple bug #15122724.
      if (
        isIOS &&
        targetElement.setSelectionRange &&
        targetElement.type.indexOf("date") !== 0 &&
        targetElement.type !== "time" &&
        targetElement.type !== "month" &&
        targetElement.type !== "number"
      ) {
        length = targetElement.value.length;
        targetElement.focus();
        targetElement.setSelectionRange(length, length);
      } else {
        targetElement.focus();
      }
      if (isIOS && targetElement.toString().indexOf("Select")) {
        // 解决Ios下使用fastClick导致select 聚焦跳转的问题
        event.preventDefault();
      }
    };

    /**
     * 全局注册组件
     */
    const requireComponent = require.context(
      // 其组件目录的相对路径
      "@/components",
      // 是否查询其子目录
      false,
      // 匹配基础组件文件名的正则表达式
      /Base[A-Z]\w+\.(vue|js)$/
    );

    requireComponent.keys().forEach((fileName) => {
      // 获取组件配置
      const componentConfig = requireComponent(fileName);

      // 获取组件的 PascalCase 命名
      const componentName = upperFirst(
        camelCase(
          // 剥去文件名开头的 `./` 和结尾的扩展名
          fileName.replace(/^\.\/(.*)\.\w+$/, "$1")
        )
      );

      // 全局注册组件
      app.component(
        componentName,
        // 如果这个组件选项是通过 `export default` 导出的，
        // 那么就会优先使用 `.default`，
        // 否则回退到使用模块的根。
        componentConfig.default || componentConfig
      );
    });
  },
};
