import axios from "./http";

// 获取城市
export const getCity = () => {
  return axios({
    method: "get",
    url: "/api/v1/common/city",
  });
};
// 行业树
export const getIndustry = () => {
  return axios({
    method: "get",
    url: "/api/v1/common/industry",
  });
};

// 获取历史版本列表
export const getVersionList = () => {
  return axios({
    method: "get",
    url: "/api/v1/common/release_history/list",
  });
};

// 文件上传
export const fileUplload = (formData) => {
  return axios({
    method: "post",
    url: "/api/v1/common/upload",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

// 检查是否需要更新
export const checkUpdateVersion = () => {
  return axios({
    method: "get",
    url: "/api/v1/common/release_history/max",
  });
  // return new Promise((yes) => {
  //   setTimeout(()=>{
  //     yes({
  //       data: {
  //         type: "package", // package /  wgt
  //         upgrade_type: "force", // force /  weak
  //         message: "当前是弱更新哟. 1! ",
  //         url: "https://static-mp-c78a8ece-7ab7-46da-82af-e2b3fb9c9fc8.next.bspapp.com",
  //         version: "1.0.0",
  //       },
  //     });
  //   },1300)

  // });
};

// 地点-关键词搜索
export const addressSearch = (keyword) => {
  return axios({
    method: "get",
    url: "/api/v1/common/lbs/place_suggestion",
    params: {
      keyword,
    },
  });
};

// 逆地址搜索
export const coordAddress = (params) => {
  return axios({
    method: "get",
    url: "/api/v1/common/lbs/geo_coder",
    params,
  });
};

// 生成静态图
export const sendStaticMap = (params) => {
  return axios({
    method: "post",
    url: "/api/v1/poi/static_map",
    data: params,
  });
};
