/**
 * 异步加载地图
 * @param {() => void} callback
 */
export default function loadMapScript(callback) {
  if (window.TMap) {
    callback();
    return true;
  }
  var script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    //IE
    script.onreadystatechange = function () {
      if (script.readyState == "loaded" || script.readyState == "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    //Others
    script.onload = function () {
      callback();
    };
  }
  script.src = `https://map.qq.com/api/gljs?v=1.exp&libraries=service,visualization&key=${process.env.VUE_APP_MAP_KEY}`;
  document.getElementsByTagName("head")[0].appendChild(script);
}
