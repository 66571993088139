<!-- 底部导航 -->
<template>
  <section class="do-com-tabbar">
    <router-link to="/" tag="div">
      <p></p>
      <span>首页</span>
    </router-link>
    <router-link to="/hour-hot" tag="div">
      <p></p>
      <span>24热力</span>
    </router-link>
    <router-link to="/information" tag="div">
      <p></p>
      <span>资讯</span>
    </router-link>
    <router-link to="/my" tag="div">
      <p></p>
      <span>我的</span>
    </router-link>
  </section>
</template>

<script setup></script>
<style lang="scss" scoped>
.do-com-tabbar {
  z-index: 5;
  width: 100%;
  background-color: #ffffff;
  @include border-top();
  position: fixed;
  box-sizing: content-box;
  bottom: 0;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  @include flex();
  a {
    flex: 1;
    @include flex-cloumn();
    height: 0.98rem;
    span {
      font-size: 0.2rem;
      font-weight: 400;
      color: #969799;
      transition: all 0.2s;
      line-height: 1;
      margin-top: 0.06rem;
    }
    p {
      width: 0.45rem;
      height: 0.45rem;
      transition: all 0.2s ease;
    }

    &:nth-child(1) {
      p {
        @include bg("../assets/images/tabbar/ficon_01");
      }
    }

    &:nth-child(2) {
      p {
        @include bg("../assets/images/tabbar/ficon_02");
      }
    }

    &:nth-child(3) {
      p {
        @include bg("../assets/images/tabbar/ficon_03");
      }
    }

    &:nth-child(4) {
      p {
        @include bg("../assets/images/tabbar/ficon_04");
      }
    }
    &.router-link-active {
      span {
        color: $col_main;
      }

      &:nth-child(1) {
        p {
          @include bg("../assets/images/tabbar/ficon_01_curr");
        }
      }

      &:nth-child(2) {
        p {
          @include bg("../assets/images/tabbar/ficon_02_curr");
        }
      }

      &:nth-child(3) {
        p {
          @include bg("../assets/images/tabbar/ficon_03_curr");
        }
      }

      &:nth-child(4) {
        p {
          @include bg("../assets/images/tabbar/ficon_04_curr");
        }
      }
    }
  }
}
</style>
