import { createRouter, createWebHashHistory } from "vue-router";
import Store from "@/store";

/**  路由 meta规则
 * power  ：路由跳转动画页面等级，小->大 则 1级页面->n级页面
 * auth  : 判断是否需要登录 才能进入
 **/

const routes = [
  {
    // 首页
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "auth-code" */ "../views/home/index.vue"),
    meta: {
      power: 1,
      keepAlive: true,
      tabbar: true,
      auth: true,
    },
  },
  {
    // 资讯
    path: "/information",
    name: "information",
    component: () =>
      import(
        /* webpackChunkName: "information" */ "../views/information/index.vue"
      ),
    meta: {
      power: 1,
      keepAlive: true,
      tabbar: true,
      auth: true,
    },
  },
  {
    // 资讯详情 - 富文本
    path: "/information/details/:id",
    name: "information-details",
    component: () =>
      import(
        /* webpackChunkName: "information-details" */ "../views/information/details/index.vue"
      ),
    meta: {
      power: 2,
      auth: true,
    },
  },
  {
    // 资讯详情 - pdf
    path: "/information/details/pdf/:id",
    name: "information-details-pdf",
    component: () =>
      import(
        /* webpackChunkName: "information-details-pdf" */ "../views/information/details/pdf.vue"
      ),
    meta: {
      power: 2,
      auth: true,
    },
  },
  {
    // 我的-获取验证码
    path: "/hour-hot",
    name: "hour-hot",
    component: () =>
      import(/* webpackChunkName: "auth-code" */ "../views/hour-hot/index.vue"),
    meta: {
      tabbar: true,
      auth: true,
      keepAlive: true,
    },
  },
  //  登陆
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/login/index.vue"),
    meta: {
      power: 1,
    },
  },
  {
    path: "/datamap",
    name: "datamap",
    component: () =>
      import(/* webpackChunkName: "datamap" */ "../views/datamap/index.vue"),
    meta: {
      auth: true,
      keepAlive: true,
    },
  },
  // 我的
  {
    path: "/my",
    name: "my",
    component: () =>
      import(/* webpackChunkName: "my" */ "../views/my/index.vue"),
    meta: {
      keepAlive: true,
      auth: true,
      tabbar: true,
      power: 1,
    },
  },
  // 我的-新消息通知
  {
    path: "/my/message",
    name: "my-message",
    component: () =>
      import(
        /* webpackChunkName: "my-messagey" */ "../views/my/message/index.vue"
      ),
    meta: {
      power: 2,
      auth: true,
    },
  },
  // 我的-店铺管理
  {
    path: "/my/store-management",
    name: "my-store-management",
    component: () =>
      import(
        /* webpackChunkName: "my-store-management" */ "../views/my/store-management/index.vue"
      ),
    meta: {
      power: 2,
      auth: true,
    },
  },
  // 我的-店铺认领
  {
    path: "/my/store-claim",
    name: "my-store-claim",
    component: () =>
      import(
        /* webpackChunkName: "my-store-claim" */ "../views/my/store-management/claim.vue"
      ),
    meta: {
      auth: true,
      keepAlive: true,
      power: 2,
    },
  },
  // 我的-店铺地图地址选择
  {
    path: "/my/store-location",
    name: "my-store-location",
    component: () =>
      import(
        /* webpackChunkName: "my-store-location" */ "../views/my/store-management/location.vue"
      ),
    meta: {
      power: 2,
      auth: true,
    },
  },
  // 我的-店铺环境信号采集
  {
    path: "/my/store-acquisition-signal",
    name: "my-store-acquisition-signal",
    component: () =>
      import(
        /* webpackChunkName: "my-store-acquisition-signal" */ "../views/my/store-management/acquisition-signal.vue"
      ),
    meta: {
      power: 2,
      auth: true,
    },
  },

  // 我的-申请注销协议页面
  {
    path: "/my/writeoff",
    name: "my-writeoff",
    component: () =>
      import(
        /* webpackChunkName: "my-writeoff" */ "../views/my/write-off/index.vue"
      ),
    meta: {
      power: 2,
      auth: true,
    },
  },
  // 我的-关于E客流
  {
    path: "/my/regulations",
    name: "my-regulations",
    component: () =>
      import(
        /* webpackChunkName: "my-regulations" */ "../views/my/regulations/index.vue"
      ),
    meta: {
      power: 2,
      auth: true,
    },
  },
  // 我的-历史版本列表
  {
    path: "/my/regulations/versions",
    name: "my-regulations-versions",
    component: () =>
      import(
        /* webpackChunkName: "my-regulations-versions" */ "../views/my/regulations/versions/index.vue"
      ),
    meta: {
      power: 3,
      auth: true,
    },
  },
  // 我的-历史版本详情
  {
    path: "/my/regulations/versions/details",
    name: "my-regulations-versions-details",
    component: () =>
      import(
        /* webpackChunkName: "my-regulations-versions-details" */ "../views/my/regulations/versions/details.vue"
      ),
    meta: {
      power: 3,
      auth: true,
    },
  },
  {
    // 我的-第三方账号绑定
    path: "/my/third-party",
    name: "third-party",
    component: () =>
      import(
        /* webpackChunkName: "third-party" */ "../views/my/third-party/index.vue"
      ),
    meta: {
      power: 2,
      auth: true,
    },
  },
  {
    // 我的-个人中心页面
    path: "/my/personal-center",
    name: "personal-center",
    component: () =>
      import(
        /* webpackChunkName: "personalcenter" */ "../views/my/personal-center/index.vue"
      ),
    meta: {
      power: 2,
      auth: true,
    },
  },
  {
    // 我的-个人中心页面  修改 昵称 / 姓名 / 邮箱 公共页面
    path: "/my/personal-center/set-base-info/:id",
    name: "set-base-info",
    component: () =>
      import(
        /* webpackChunkName: "setbaseinfo" */ "../views/my/personal-center/set-base-info.vue"
      ),
    meta: {
      power: 3,
      auth: true,
    },
  },
  {
    // 我的-更改手机号
    path: "/my/updata-phone",
    name: "updata-phone",
    component: () =>
      import(
        /* webpackChunkName: "updata-phone" */ "../views/my/updata-phone/index.vue"
      ),
    meta: {
      power: 2,
      auth: true,
    },
  },
  {
    // 我的-获取验证码
    path: "/auth-code/:id",
    name: "auth-code",
    component: () =>
      import(
        /* webpackChunkName: "auth-code" */ "../views/auth-code/index.vue"
      ),
    meta: {
      power: 3,
    },
  },
  {
    // 周边数据
    path: "/home/around",
    name: "around",
    meta: {
      auth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "auth-code" */ "../views/home/around/index.vue"
      ),
  },
  {
    // 我的收藏
    path: "/my/collect",
    name: "collect",
    meta: {
      auth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "auth-code" */ "../views/my/collect/index.vue"
      ),
  },
  {
    // 首页搜索
    path: "/search",
    name: "search",
    meta: {
      auth: true,
      keepAlive: true,
    },
    component: () =>
      import(/* webpackChunkName: "auth-code" */ "../views/search/index.vue"),
  },
  {
    // 首页品牌结果
    path: "/search/brand-end",
    name: "search-brand-end",
    meta: {
      auth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "auth-code" */ "../views/search/brand-end/index.vue"
      ),
  },
  {
    // 首页品牌地图
    path: "/search/brand-map",
    name: "search-brand-map",
    meta: {
      auth: true,
      keepAlive: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "auth-code" */ "../views/search/brand-map/index.vue"
      ),
  },
  {
    // 及刻指数
    path: "/jk-index",
    name: "jk-index",
    meta: {
      auth: true,
    },
    component: () =>
      import(/* webpackChunkName: "jk-index" */ "../views/jk-index/index.vue"),
  },
  {
    // 品牌店铺列表
    path: "/search/brand-map/brand-list",
    name: "brand-list",
    meta: {
      auth: true,
      keepAlive: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "auth-code" */ "../views/search/brand-map/brand-list/index.vue"
      ),
  },
  //  协议页面
  {
    path: "/web-slot",
    name: "web-slot",
    component: () =>
      import(
        /* webpackChunkName: "login" */ "../views/login/web-slot/index.vue"
      ),
    meta: {
      power: 2,
    },
  },
];

let filterList = ["/web-slot"];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

// 路由前置钩子
router.beforeEach((to, from, next) => {
  const storeUser = Store.state.user;

  // if (from.name === "my-store-management" && to.name === "my-store-claim") {
  //   to.meta.keepAlive = false;
  //   console.log("111111", to);
  // } else if (
  //   from.name === "my-store-acquisition-signal" &&
  //   to.name === "my-store-claim"
  // ) {
  //   to.meta.keepAlive = false;
  //   console.log("22222");
  //   // to.meta.keepAlive = true;
  //   // console.log("to.meta", from, to);
  // } else if (
  //   from.name === "my-store-claim" &&
  //   to.name === "my-store-management"
  // ) {
  //   console.log("33333");
  // }
  // if (from.meta.usePreCache) {
  //   from.meta.keepAlive = false;
  //   console.log("from.meta", from, to);
  // }

  // else {
  //   to.meta.keepAlive = false;
  // }
  // 路由过度动画
  var toPower = to.meta.power,
    fromPower = from.meta.power;
  if (toPower && fromPower) {
    if (toPower < fromPower) {
      Store.commit("setAnimate", "vux-pop-in");
    } else if (toPower > fromPower) {
      Store.commit("setAnimate", "vux-pop-out");
    } else {
      Store.commit("setAnimate", "vux-pop");
    }
  } else {
    Store.commit("setAnimate", "vux-pop");
  }

  // 过虑不需要登陆的路由
  if (filterList.includes(to.path)) {
    next();
    return;
    // 处理当前手机有缓存没同意协议的情况
  }

  // 判断不是协议页面和不是登陆页面  并且协议为false就清除token重新获取协议
  if (window.plus) {
    const plus = window.plus;
    console.log("plus.os.name", plus.os.name);
    if (plus.os.name !== "Android") {
      if (
        !filterList.includes(to.path) &&
        !+localStorage.getItem("agreement") &&
        to.path !== "/login"
      ) {
        Store.dispatch("logout");
        next("/login");
        return;
      }
    }
  }

  // 访问页面需要登陆权限
  if (to.meta && to.meta.auth) {
    if (!storeUser.token) {
      next("/login");
      return;
    }
  }
  next();
});

export default router;
