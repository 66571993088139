/**
 * 公共js
 * @class comJs
 */
class comJs {
  constructor() {
    this.bili = this.getBili(); //比例
    this.remAdaptive(); //rem设配
    this.curFacility = this.isFacility(); //设备
    this.rewriteToFixed();
    this.getNabbarHeight();
    this.debounce = null;
    // 状态栏 + 导航栏高度
  }

  guuid() {
    function S4() {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }
    return (
      S4() +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      S4() +
      S4()
    );
  }
  getNabbarHeight() {
    console.log("this.getStatusBarHeight()", this.getStatusBarHeight());
    return this.bili * 0.88 + this.getStatusBarHeight() - this.bili * 0.88;
  }
  /**
   * 获得状态栏高度
   */
  getStatusBarHeight() {
    var immersed = 0;
    var ms = /Html5Plus\/.+\s\(.*(Immersed\/(\d+\.?\d*).*)\)/gi.exec(
      navigator.userAgent
    );
    if (ms && ms.length >= 3) {
      // 当前环境为沉浸式状态栏模式
      immersed = parseFloat(ms[2]); // 获取状态栏的高度
    }
    return immersed;
  }
  /**
   * 获取rem值
   */
  getBili() {
    var _w = window.screen.availWidth;
    _w = _w > 1242 ? 1242 : _w;
    var _bili = _w / 7.5;
    return _bili;
  }
  /**
   * rem适配
   */
  remAdaptive() {
    document.documentElement.style.fontSize = this.bili + "px";
    // window.addEventListener("resize", () => {
    //     console.log("pppp")
    //     document.documentElement.style.fontSize = this.bili + 'px';
    // })
  }

  /**
   * 判断是否未iphonex xr xs
   */
  isIPhoneXSeries() {
    console.log(
      "/iphone/gi.test(window.navigator.userAgent)",
      window.navigator.userAgent
    );
    let isIPhoneX =
      /iphone/gi.test(window.navigator.userAgent) &&
      window.devicePixelRatio &&
      window.devicePixelRatio === 3 &&
      window.screen.width === 375 &&
      window.screen.height === 812;
    let isIPhoneXSMax =
      /iphone/gi.test(window.navigator.userAgent) &&
      window.devicePixelRatio &&
      window.devicePixelRatio === 3 &&
      window.screen.width === 414 &&
      window.screen.height === 896;
    let isIPhoneXR =
      /iphone/gi.test(window.navigator.userAgent) &&
      window.devicePixelRatio &&
      window.devicePixelRatio === 2 &&
      window.screen.width === 375 &&
      window.screen.height === 812;

    if (isIPhoneX || isIPhoneXSMax || isIPhoneXR) {
      return true;
    } else {
      return false;
    }
  }
  geTel(tel) {
    var reg = /^(\d{3})\d{4}(\d{4})$/;
    return tel.replace(reg, "$1****$2");
  }
  /**
   * 解决android虚拟键盘挡住文本框
   * @param {*} event  元素节点
   */
  android(event) {
    var that = event.currentTarget;
    setTimeout(function () {
      that.scrollIntoViewIfNeeded();
    }, 400);
  }
  //区分android和ios
  dicernPhone() {
    let u = navigator.userAgent;
    let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; // android终端
    let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    if (isAndroid) {
      return 0;
    } else if (isIOS) {
      return 1;
    } else {
      return 3;
    }
  }
  /**
   * 判断设备
   */
  isFacility() {
    let isApp = navigator.userAgent.indexOf("Html5Plus") > -1, // 判断是通过游览器打开的还是通过app打开的
      isX = this.isIPhoneXSeries();
    if (isX) {
      isApp = false;
    }

    return {
      isX,
      isApp,
    };
  }
  //判断是否是hbuild打包
  isH5() {
    return !window.plus;
  }
  /**
   *重写toFixed,解决精度丢失
   */
  rewriteToFixed() {
    Number.prototype.toFixed = function (digit) {
      const num = this,
        cardinalNum = num < 0 ? -0.5 : 0.5, //4舍5入基数
        times = Math.pow(10, digit); //保留位数
      let des = num * times + cardinalNum,
        _len = 0; //需要补0的长度
      des = parseInt(des, 10) / times + "";
      if (des.indexOf(".") == -1) {
        _len = digit;
        des += digit != 0 ? "." : "";
      } else {
        _len = des.length - des.indexOf(".");
        _len = digit - _len + 1;
      }
      for (var i = 0; i < _len; i++) {
        des += "0";
      }
      return des + "";
    };
  }
  //安卓虚拟
  androidInput() {
    var _hegith = document.body.offsetHeight;
    if (/Android/gi.test(navigator.userAgent)) {
      window.onresize = () => {
        if (
          document.activeElement.tagName == "INPUT" ||
          document.activeElement.tagName == "TEXTAREA"
        ) {
          if (!this.hasClass(document.activeElement, "inputPart")) {
            document.body.style.height = _hegith + "px";
          } else {
            document.body.style.height = "100%";
          }
        }
      };
    }
  }
  /**
   * 安卓虚拟键盘挡住文本框
   * @memberof comJs
   */
  android = (event) => {
    var that = event.currentTarget;
    setTimeout(function () {
      that.scrollIntoViewIfNeeded();
    }, 400);
  };
  //判断有没有class
  hasClass(elements, cName) {
    return !!elements.className.match(
      new RegExp("(\\s|^)" + cName + "(\\s|$)")
    );
  }
  // 添加class
  addClass(elements, cName) {
    if (!this.hasClass(elements, cName)) {
      elements.className += " " + cName;
    }
  }

  // 删除class
  removeClass(elements, cName) {
    if (this.hasClass(elements, cName)) {
      elements.className = elements.className.replace(
        new RegExp("(\\s|^)" + cName + "(\\s|$)"),
        " "
      );
    }
  }

  // 获取随机数
  guid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0;
      const v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
  // 节流
  setDebounce(cb, time = 500) {
    if (this.debounce) {
      clearTimeout(this.debounce);
    }
    this.debounce = setTimeout(() => {
      cb();
    }, time);
  }
}
const _comJs = new comJs();
export default _comJs;

/**
 * 防抖函数
 * @param {()=>void} fn
 * @param {number} delay - 延迟时间
 * @returns
 */
export function debounce(fn, delay) {
  // 定时器
  let timer = null;

  // 将debounce处理结果当作函数返回
  return function () {
    // 保留调用时的this上下文
    let context = this;
    // 保留调用时传入的参数
    let args = arguments;

    // 每次事件被触发时，都去清除之前的旧定时器
    if (timer) {
      clearTimeout(timer);
    }
    // 设立新定时器
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  };
}

/**
 * 计算文字宽度
 * @param {number} text - 要计算的文字
 * @returns {number} 文字的宽度
 */
export function getTextWidth(text) {
  const canvas = document.createElement("canvas");
  const dom = canvas.getContext("2d");
  dom.font = `500 ${_comJs.getBili() * 0.28}px PingFangSC-Regular, PingFang SC`;
  let width = dom.measureText(text).width;
  return width;
}
