<template>
  <van-nav-bar
    class="do-com-navbar"
    :safe-area-inset-top="true"
    :title="title"
    :left-arrow="backText"
    @click-left="onClickLeft"
    :style="{ paddingTop: $Hbuild.getStatusBarHeight() + 'px' }"
  >
    <template #right>
      <slot></slot>
    </template>
  </van-nav-bar>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";
const props = defineProps(["backText", "title"]);
const emit = defineEmits(["back"]);
const onClickLeft = (e) => {
  emit("back", e);
  console.log("prop1123s", props);
};
</script>

<style lang="scss">
.do-com-navbar {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);

  .van-nav-bar__content {
    height: $navbar_hegiht;
  }
  .van-nav-bar__left {
    padding-left: 0.32rem;
  }
  .van-nav-bar__right {
    padding-right: 0.32rem;
  }
  .van-icon-arrow-left {
    color: #191919 !important;
    font-size: 0.45rem;
  }
  .van-nav-bar__title {
    font-size: 0.36rem;
    font-weight: 500;
    color: #03050f;
    height: 100%;
    display: flex;
    align-items: center;
  }
}
</style>
