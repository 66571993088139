import axios from "./http";

// 获取验证码
export const getAccountCaptcha = (params = {}) => {
  return axios({
    method: "post",
    url: "/api/v1/account/captcha",
    // params: params,
    data: params,
  });
};
// 用户登陆
export const sendLogin = (params = {}) => {
  return axios({
    method: "post",
    url: "/api/v1/account/login",
    data: params,
  });
};
// 获取用户信息
export const getAccountInfo = () => {
  return axios({
    method: "get",
    url: "/api/v1/account/info",
  });
};
// 更改手机号
export const sendUpdataPhpme = (params = {}) => {
  return axios({
    method: "put",
    url: "/api/v1/account/phone",
    data: params,
  });
};
// 个人信息_修改
export const sendAccountInfo = (params = {}) => {
  return axios({
    method: "put",
    url: "/api/v1/account/info",
    data: params,
  });
};

// 用户注销
export const sendWriteOff = (params = {}) => {
  return axios({
    method: "post",
    url: "/api/v1/account/logout",
    data: params,
  });
};

// 店铺认领列表
export const getConfirmShops = (params = {}) => {
  return axios({
    method: "get",
    url: "/api/v1/account/confirm_shops",
    params,
  });
};

// 认领店铺
export const sendConfirmShops = (params = {}) => {
  return axios({
    method: "post",
    url: "/api/v1/account/confirm_shops",
    data: params,
  });
};
