import { getCity, getIndustry } from "@/axios/common";
const defaultState = {
  citys: [],
  coordinate: "",
  industry: [],
};

export default {
  state: { ...defaultState },
  mutations: {
    setCity(state, val) {
      state.citys = val.tree;
    },
    setIndustry(state, val) {
      state.industry = val.tree;
    },
    clearCommon(state) {
      state.citys = [];
      state.industry = [];
      state.coordinate = "";
    },
    clearCommonData(state) {
      state.citys = [];
      state.industry = [];
      console.log("clearCommonData");
    },
    setCoordinate(state, coordinate) {
      state.coordinate = coordinate;
    },
  },
  actions: {
    // 获取城市
    async getCity({ commit, state }) {
      if (state.citys && state.citys.length) return;

      try {
        const res = await getCity();
        commit("setCity", res.data);
      } catch (error) {}
    },
    // 获取行业
    async getIndustry({ commit }) {
      try {
        const res = await getIndustry();
        commit("setIndustry", res.data);
      } catch (error) {}
    },
  },
};
