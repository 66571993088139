<template>
  <div class="map-wrapper">
    <div class="t-map" ref="mapElementRef"></div>
    <slot></slot>
  </div>
</template>

<script setup>
import {
  onUnmounted,
  onDeactivated,
  onActivated,
  ref,
  defineEmits,
  onMounted,
  defineProps,
} from "vue";
import loadMapScript from "@/util/loadmap";
const emit = defineEmits(["init"]);
const props = defineProps(["config"]);
const mapElementRef = ref();

/** 地图实例 */
let map = null;

/** 是否已加载地图 */
let isLoading = false;

onMounted(() => {
  isLoading = true;
  // 调用时机为首次挂载
  // 以及每次从缓存中被重新插入时
  loadMapScript(initMap);
});

onActivated(() => {
  // 调用时机为首次挂载
  // 以及每次从缓存中被重新插入时
  if (!isLoading) {
    loadMapScript(initMap);
  }
});

onDeactivated(() => {
  isLoading = false;
  map?.destroy();
});
onUnmounted(() => {
  isLoading = false;
  map?.destroy();
});

/** 初始化地图 */
function initMap() {
  let position = props.config?.coord?.split(",");
  let center = position && new window.TMap.LatLng(+position[0], +position[1]);
  let config = {
    viewMode: "2D",
    zoom: 16,
    showControl: false,
    mapStyleId: "style1",
    ...(props?.config || {}),
  };
  if (center) {
    config.center = center;
  }
  map = new window.TMap.Map(mapElementRef.value, config);
  window.map = map;

  emit("init", map);
}
</script>

<style scoped lang="scss">
.map-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  .t-map {
    position: relative;
    width: 100%;
    height: 100%;
  }
}
</style>
