import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

const modulesFiles = require.context("./modules", false, /\.js$/);
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  // set './user.js' => 'user'
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, "$1");
  const value = modulesFiles(modulePath);
  modules[moduleName] = value.default;
  return modules;
}, {});

export default createStore({
  state: {
    animate: "vux-pop", //转场动画
    appVersion: "", // app 当前版本
    upgradeInfo: {
      // 升级版本信息
      type: "", // package /  wgt
      upgrade_type: "", // force /  weak
      message: "",
      url: "",
      version: "",
    },
    refresh: false,
  },
  mutations: {
    setRefresh(state) {
      state.refresh = true;
      setTimeout(() => {
        state.refresh = false;
      }, 100);
    },
    setAnimate(state, val) {
      state.animate = val;
    },
    setAppVersion(state, val) {
      state.appVersion = val;
    },
    setUpgradeInfo(state, val) {
      if (JSON.stringify(val) === JSON.stringify(state.upgradeInfo)) return;
      state.upgradeInfo = val;
    },
  },
  actions: {},
  modules,
  plugins: [
    // 默认储存在localstorage
    createPersistedState({
      // 指定模块
      key: "rabbitstore-client",
      paths: ["user", "common"],
    }),
  ],
});
