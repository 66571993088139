import Axios from "axios";
import store from "../store";
import router from "../router";
import { Toast } from "vant";
import ComJs from "@/util";
import Hbuild from "@/util/hbuild";

class Http {
  constructor() {
    // 请求中队列
    this.cancelPendinMap = new Map();
    this.axios = new Axios.create({
      baseURL: "https://eapi.ekeliu.com",
      // baseURL: process.env.VUE_APP_BASE_API,
      timeout: 35000,
    });
    // 请求拦截器
    this.axios.interceptors.request.use(
      this.interceptorsRequest,
      function (error) {
        return Promise.reject(error);
      }
    );

    // 响应拦截器
    this.axios.interceptors.response.use(
      this.interceptorsResponse,
      this.responseError
    );
  }

  // 请求拦截器
  interceptorsRequest = (config) => {
    const version = Hbuild.appVersion;
    if (store.state.user.token) {
      config.headers.Authorization = `Bearer ${store.state.user.token}`;
    }

    // config.headers[
    //   "X-EKL"
    // ] = `22.560578,114.052586|${Hbuild.getSystemType()}|${version}|${ComJs.guuid()}`;
    config.headers["X-EKL"] = `${
      store.state.common.coordinate
    }|${Hbuild.getSystemType()}|${version}|${ComJs.guuid()}`;

    // Do something before request is sent
    return config;
  };

  // 响应拦截器
  interceptorsResponse = (response) => {
    // 存在数据，直接返回数据
    if (response.data) {
      return response.data;
    }
    return response;
  };

  // 取消重复请求方法
  cancelRepetitionRequest = (config) => {
    let { url, method, params, data } = config;
    if (typeof data === "string") data = JSON.parse(data); // response里面返回的config.data是个字符串对象
    const pendingKey = [
      url,
      method,
      JSON.stringify(params),
      JSON.stringify(data),
    ].join("&");
    this.cancelPendinMap.forEach((cancel) => {
      cancel();
    });
    this.cancelPendinMap.clear();
    config.cancelToken =
      config.cancelToken ||
      new Axios.CancelToken((cancel) => {
        this.cancelPendinMap.set(pendingKey, cancel);
      });

    return this.axios(config).then((res) => {
      this.cancelPendinMap.clear();
      return res;
    });
  };

  // 响应错误毁掉
  responseError = (error) => {
    // 请求被取消
    if (error.code === "ERR_CANCELED") {
      return Promise.reject();
    }
    // 服务端没响应 （没有返回值）
    if (!error.response) {
      Toast("服务器未响应");
      return Promise.reject();
    }

    // 500 服务端错误
    if (error.response.status === 500) {
      Toast("服务器错误");
      return Promise.reject();
    }

    console.log("error", error);

    if (error && error.response && error.response.data) {
      // 40141 40100  登陆过期
      if (
        error.response.config.url !== "/api/v1/account/login" &&
        (error.response.data.code === 40141 ||
          error.response.data.code === 40100)
      ) {
        Toast("当前登陆状态过期，请重新登陆");
        store.dispatch("logout");
        router.push("/login");
        return Promise.reject();
      }
      // 40142 全局强制升级app 弹窗
      if (error.response.data.code === 40142) {
        store.commit("setUpgradeInfo", error.response.data || {});
        // {
        //   type: "force", // package /  wgt
        //   upgrade_type: "force", // force /  weak
        //   message: "当前版本较低，请升级新版本",
        //   url: "https://imgtest.clickwifi.net/front-end-h5-cdn/test/H5BB0743F1.wgt",
        //   version: "1.0.0",
        // }
        return Promise.reject();
      }
    }

    // 应用级别响应成功，但是状态吗返回错误
    if (error && error.response && error.response.data) {
      return Promise.reject(error.response.data);
    }

    // 服务响应失败
    if (error.code === "ECONNABORTED") {
      Toast("服务端超时 ");
    }

    return Promise.reject();
  };
}

const http = new Http();
export default http.axios;
export const cancelRepetitionRequest = http.cancelRepetitionRequest;
